angular.module('services').factory "SubscriptionDocumentDefinitionService", ($resource) ->
  libraryTemplate = $resource "/api/v1/subscription_document_definitions/:id",
    id: "@id"
  ,
    update:
      method: "PATCH"

    getDocumentTypes:
      method: "GET"
      url: "/api/v1/subscription_document_definitions/get_document_types"

    retrieveSignerConfigurations:
      method: "GET"
      url: "/api/v1/subscription_document_definitions/:id/retrieve_signer_configurations"
      isArray: false

    getPreview:
      method: "GET"
      url: "/api/v1/subscription_document_definitions/:id/preview"
      headers:
        accept: 'application/pdf'
      responseType: 'arraybuffer',
      transformResponse: (data) ->
        if data
          pdf = new Blob([data],
            type: 'application/pdf'
          )
          return pdf: pdf
