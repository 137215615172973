angular.module('directives').directive "investorSelectProfile", (ToolTips, urlBuilder, ExternalAddOnPrompter) ->
  restrict: "E"
  scope:
    createInvestmentCallback: '&'
    privateAccessFund: '='
    dialogView: '='
    signMethod: "="
  templateUrl: "areas/private_equity_funds/private_access_funds/components/\
  investor_select_profile/investor_select_profile.html"

  controller:
    ($scope, InvestorProfileService, IcnIdentity, IcnUtility, Dialog, InvestmentService, InvestmentBlocksService,
    Redirects, $modal, $location, $routeParams, CurrencySelector,
    PreApprovalQuestionnaireForm, InvestmentShareClassService) ->
      $scope.btnText = "View & Sign The Subscription Agreement"
      $scope.hasSeries = false
      $scope.requiredQuestForInvestorProfile = false
      $scope.toolTips = ToolTips
      $scope.investment =
        amount: ""
      $scope.formData =
        investor_profile: undefined
        selectedReferralCode: ''
        selectedSeries: undefined
        onPage: 'investor-select-profile'
        amounts: []

      $scope.availableInvestmentShareClasses = []
      $scope.filteredInvestmentShareClasses = []
      $scope.subBook = {}
      $scope.hasInvestmentShareClasses = false
      $scope.hasQuestionnaireAnswers = ->
        $routeParams.questionnaire_answered == 'true' &&
        window.localStorage.getItem('questionnaire_answers') != null
      $scope.investmentShareClass = {}
      $scope.showInvestorProfiles = !IcnIdentity.data.white_label_partner?.hide_investor_profiles
      $scope.hiddenNewIssues = IcnIdentity.data.white_label_partner?.hidden_new_issues
      $scope.investor_profile_id = $routeParams.investor_profile_id || parseInt($location.search()["invProfId"])

      angular.element('.money-input').maskMoney({precision: 0})

      $scope.has_supplemental_responses = false

      $scope.currency = CurrencySelector.fromPaf($scope.privateAccessFund)

      $scope.externalAddOnPrompter =
        ExternalAddOnPrompter.new(
          privateAccessFund: $scope.privateAccessFund,
          allowExternalAddOns: $scope.privateAccessFund.allow_external_add_ons
        )

      $scope.commitmentInvalid = ->
        if $scope.investment.amount
          strippedNum = parseFloat(IcnUtility.unformatCurrency($scope.investment.amount))
          isNaN(strippedNum) || strippedNum < 1
        else
          true

      $scope.requiresSubDocCode = ->
        $scope.privateAccessFund.sub_doc_code_required

      $scope.checkSubDocCode = (form) ->
        $scope.subDocCodeValid = false
        field_value = $scope.formData.subDocCode
        return if field_value == null || field_value == undefined || field_value == ''
        InvestmentService.validateSubDocCode($scope.privateAccessFund.id, field_value).then (response) ->
          form.sub_doc_code.$setValidity('valid', response.valid_code)
          $scope.subDocCodeValid = response.valid_code
          $scope.subBooks = response.subscription_docs
          $scope.subBook.selectedItem = null

      $scope.showSubDocCodeError = (form) ->
        form.sub_doc_code &&
          !form.sub_doc_code.$valid &&
          form.sub_doc_code.$dirty

      $scope.checkInvestmentShareClassCode = ->
        $scope.investmentShareClassCodeValid = false
        field_value = $scope.createInvestmentForm.investmentShareClassCode.$viewValue
        return unless field_value?.length

        InvestmentShareClassService.availableForLimitCode(
          $scope.privateAccessFund.id,
          IcnIdentity.data.white_label_partner?.id,
          IcnIdentity.user?.firm_id,
          field_value).then (availableShareClasses) ->
            $scope.hasInvestmentShareClasses = availableShareClasses.length > 0
            $scope.availableInvestmentShareClasses =
              _.reject(availableShareClasses, (sc) -> sc.hide_on_investment_creation)

            if $scope.availableInvestmentShareClasses.length
              if $scope.subBooks.length == 1
                filterInvestmentShareClassesBySubDocType($scope.subBooks[0].sub_doc_type_id)
              else if $scope.subBook.selectedItem
                filterInvestmentShareClassesBySubDocType($scope.subBook.selectedItem.sub_doc_type_id)
              else
                filterInvestmentShareClassesBySubDocType(0)

              if $scope.filteredInvestmentShareClasses.length
                $scope.investmentShareClassCodeValid = true
                $scope.createInvestmentForm.investmentShareClassCode.$setValidity('valid', true)

                if $routeParams.investment_share_class_id
                  $scope.investmentShareClass.selectedItem =
                    _.findwhere(
                      $scope.filteredInvestmentShareClasses,
                      { id: parseInt($routeParams.investment_share_class_id) }
                    )
              else
                $scope.createInvestmentForm.investmentShareClassCode.$setValidity('valid', false)
            else
              $scope.createInvestmentForm.investmentShareClassCode.$setValidity('valid', false)

      $scope.commitmentInvalid = ->
        if $scope.investment.amount
          strippedNum = parseFloat(IcnUtility.unformatCurrency($scope.investment.amount))
          isNaN(strippedNum) || strippedNum < 1
        else
          true

      $scope.showInvestmentShareClassDropdown = ->
        return false if !$scope.subBook.selectedItem? && $scope.subBooks.length != 1 || !$scope.subDocCodeValid

        $scope.filteredInvestmentShareClasses.length > 1

      $scope.showInvestmentShareClassCodeBox = ->
        ($scope.subBook.selectedItem || $scope.subBooks.length == 1) &&
        $scope.subDocCodeValid && $scope.requiresInvestmentShareClassCode()

      $scope.requiresInvestmentShareClassCode = ->
        $scope.privateAccessFund.investment_share_class_code_required

      $scope.showInvestmentShareClassCodeError = ->
        $scope.createInvestmentForm.investmentShareClassCode &&
        $scope.createInvestmentForm.investmentShareClassCode.$dirty &&
        !$scope.createInvestmentForm.investmentShareClassCode.$valid

      $scope.showSubbookDropdown = ->
        if $scope.requiresSubDocCode()
          return $scope.subBooks.length > 1 && $scope.subDocCodeValid

        return $scope.subBooks.length > 1

      investmentShareClassComplete = ->
        return true if !$scope.hasInvestmentShareClasses

        $scope.filteredInvestmentShareClasses.length == 1 || !!$scope.investmentShareClass.selectedItem

      $scope.subscriptionTypeComplete = ->
        $scope.subBooks.length <= 1 || !!$scope.subBook.selectedItem

      $scope.validQuestionnaire = ->
        !$scope.hasQuestionnaire() || ($scope.hasQuestionnaire() && $scope.hasQuestionnaireAnswers())

      validInvestmentShareClass = ->
        $scope.investmentShareClassCodeValid && investmentShareClassComplete()

      $scope.formValid = ->
        $scope.privateAccessFund && !$scope.commitmentInvalid() &&
        !$scope.investorProfileInvalid && $scope.subscriptionTypeComplete() &&
        !$scope.invalidInvestorProfile() && $scope.createInvestmentForm.$valid &&
        $scope.seriesValid() && $scope.supplementalResponseValid() &&
        $scope.subDocCodeValid && $scope.validQuestionnaire() &&
        validInvestmentShareClass()

      $scope.supplementalResponseValid = ->
        !$scope.privateAccessFund.has_investment_supplemental_form || $scope.supplementalResponseCompleted

      $scope.seriesValid = ->
        !$scope.hasSeries || $scope.formData.selectedSeries != undefined

      $scope.setCommitment = ->
        $scope.investment.amount = document.getElementsByName("section_a_amount")[0].value

      $scope.newIssuesProfileComplete = (investorProfile) ->
        return true if !investorProfile.new_issues_profile_id
        investorProfile.new_issues_profile_complete || $scope.hiddenNewIssues

      $scope.custodianManualSignatureOnly = (investorProfile) ->
        return false if !investorProfile
        investorProfile.manual_signature && !$scope.privateAccessFund.strict_signature_method


      checkQuestionnaireInvestorProfile = (inv_prof_id) ->
        PreApprovalQuestionnaireForm.questionnaireExistForInvestorProfile({
          private_access_fund_id: $scope.privateAccessFund.id,
          white_label_partner_id: $scope.wlpId,
          firm_id: IcnIdentity.user.firm_id,
          investor_profile_id: inv_prof_id
        }).then (response) ->
          $scope.requiredQuestForInvestorProfile = response.data

      $scope.changeInvestorProfile = (investorProfile) ->
        if $scope.hasQuestionnaireTemplate.account && !$scope.hasQuestionnaireTemplate.questionnaire_mandatory
          checkQuestionnaireInvestorProfile(investorProfile.id)

        if $routeParams.questionnaire_answered == 'true'
          $location.search('questionnaire_answered', null)
          window.localStorage.removeItem('questionnaire_answers')
          setQuestionnaireLinkText()

        if $routeParams.supplemental_form_answered
          $location.search('supplemental_form_answered', null)
          window.localStorage.removeItem('supplemental_form_answers')
          initSupplementalQuestions()

        # setSecondSigner And ShowIncompleteModal
        $scope.investorProfileInvalid = false
        if !investorProfile.completed && !$scope.privateAccessFund.new_issues_profile
          $scope.investorProfileInvalid = true
          Dialog.openNotifyDialog("Investor Profile Incomplete",
          "The Investor Profile must be completed before you can send a subscription agreement." +
          " <br><br>Click OK to proceed to Investor Profile.",
          true).result.then ->
            editProfile($scope.formData.investor_profile)
          return
        if $scope.privateAccessFund.new_issues_profile && !$scope.newIssuesProfileComplete(investorProfile)
          $scope.investorProfileInvalid = true
          openInvestorProfileNewIssuesIncompleteDialog(investorProfile)

      editProfile = (investor_profile) ->
        Redirects.setSavedPath($location.path())
        if IcnIdentity.isRia() || IcnIdentity.isInvestorRelations() || IcnIdentity.canHaveInvestments()
          selected_item_id = $scope.subBook.selectedItem && $scope.subBook.selectedItem.id || ''

          queryString = "&private_access_fund_id=#{$scope.privateAccessFund.id}" +
            "&sub_book_id=#{selected_item_id}" +
            "&newIssues=true&paf_override=#{$scope.privateAccessFund.id}"
          final_url = $location.path().match(/investment_management|legal_docs|investment_status/)
          queryString += "&finalUrl=" + final_url if final_url

          url = urlBuilder.investorProfileUrl(investor_profile.id, "1", queryString)

          $location.url if url

      openInvestorProfileNewIssuesIncompleteDialog = (investorProfile) ->
        opts =
          templateUrl: "dialogs/custom_confirm_dialog/custom_confirm.html"
          controller: "customConfirmDialogCtrl"
          windowClass: "small-modal"
          resolve:
            header: ->
              "Investor Profile Incomplete"
            msg: ->
              "The Investor Profile must be completed before you can sign your subscription agreement. <br><br>" +
                "Click OK to proceed to your Investor Profile."
            confirm_label: ->
              "Ok"
            cancel_label: ->
              "Cancel"
        $modal.open(opts).result.then ->
          queryString = "newIssues=true&paf_override=#{$scope.privateAccessFund.id}"

          final_url = $location.path().match(/investment_status|legal_docs/)

          if final_url
            selected_item_id = $scope.subBook.selectedItem && $scope.subBook.selectedItem.id || ''
            queryString += "&private_access_fund_id=#{$scope.privateAccessFund.id}" +
            "&sub_book_id=#{selected_item_id}&finalUrl=" + final_url

          url = urlBuilder.investorProfileUrl(investorProfile.id, "1", queryString)
          if url
            $location.url url

      checkAttestation = (investment, privateAccessFund) ->
        attestation = privateAccessFund.attestation
        attestation_exception = privateAccessFund.attestation_exception
        return false unless attestation && canShowAttestation(attestation_exception, investment)

        attestation

      canShowAttestation = (attestation_exception, investment) ->
        return true unless attestation_exception

        invest_type = attestation_exception.investment_type
        init_invest_id = investment.initial_investment_id

        invest_type != 'Add On' || !init_invest_id && invest_type != 'Initial' || init_invest_id

      $scope.setInvestment = ->
        if $scope.showInvestorProfiles &&
        $scope.formData.investor_profile &&
        !$scope.formData.investor_profile.completed
          Dialog.openNotifyDialog("Investor Profile Incomplete",
            "The Investor Profile must be completed before you can send a subscription agreement." +
            " <br><br>Click OK to proceed to Investor Profile.",
            true).result.then ->
              editProfile($scope.formData.investor_profile)
          return
        if $scope.privateAccessFund.new_issues_profile &&
        !$scope.newIssuesProfileComplete($scope.formData.investor_profile)
          openInvestorProfileNewIssuesIncompleteDialog($scope.formData.investor_profile)
          return

        if $scope.subBook.selectedItem
          sub_book_id = $scope.subBook.selectedItem.id
        else if $scope.subBooks.length == 1
          sub_book_id = $scope.subBooks[0].id
        else
          sub_book_id = null

        if $scope.investmentShareClass.selectedItem
          investmentShareClassId = $scope.investmentShareClass.selectedItem.id
        else if $scope.filteredInvestmentShareClasses.length == 1
          investmentShareClassId  = $scope.filteredInvestmentShareClasses[0].id
        else
          investmentShareClassId  = null

        attestation = checkAttestation($scope.investment, $scope.privateAccessFund)

        initialInvestmentParams =
          investor_profile_id: $scope.formData.investor_profile.id
          private_access_fund_id: $scope.privateAccessFund.id
          wlp_id: $scope.wlpId
          sub_book_id: sub_book_id
          sub_doc_code: $scope.formData.subDocCode
          investment_share_class_id: investmentShareClassId

        createInvestmentParams =
          commitment: $scope.investment.amount
          ip_id: $scope.formData.investor_profile.id
          sub_book_id: sub_book_id
          sub_doc_code: $scope.formData.subDocCode
          investment_share_class_id: investmentShareClassId
          series_id: $scope.formData.selectedSeries
          supplemental_responses: $scope.supplementalFormAnswers
          questionnaire_answers: $scope.questionnaire_answers
          external_add_on: false

        $scope.externalAddOnPrompter.prompt(
          (isExternalAddOn) ->
            createInvestmentParams.external_add_on = isExternalAddOn
            blockParams =
              ria_id: IcnIdentity.user.id
              investor_profile_id: $scope.formData.investor_profile.id
              sub_book_id: sub_book_id
              amount: parseFloat(IcnUtility.unformatCurrency($scope.investment.amount))
              external_add_on: isExternalAddOn

            if attestation
              $modal.open(
                templateUrl: "components/investment_list/dialogs/send_subscription_dialog/"+
                  "send_subscription_attestation_dialog.html"
                controller: "sendSubscriptionAttestationDialogCtrl"
                windowClass: "middle-modal"
                resolve:
                  attestation: -> attestation
              ).result.then (result) ->
                if result
                  InvestmentBlocksService.showBlocksPreInvestmentModalIfNecessary(
                    blockParams,
                    $scope.privateAccessFund,
                    -> $scope.createInvestmentCallback(createInvestmentParams)
                  )
            else
              InvestmentBlocksService.showBlocksPreInvestmentModalIfNecessary(
                blockParams,
                $scope.privateAccessFund,
                -> $scope.createInvestmentCallback(createInvestmentParams)
              )
          , initialInvestmentParams
        )

      $scope.invalidInvestorProfile = ->
        !$scope.formData.investor_profile ||
        isNaN($scope.formData.investor_profile.id) ||
        Number($scope.formData.investor_profile.id) < 1

      $scope.hasQuestionnaire = ->
        $scope.hasQuestionnaireTemplate && $scope.hasQuestionnaireTemplate.account &&
          ($scope.hasQuestionnaireTemplate.questionnaire_mandatory || $scope.requiredQuestForInvestorProfile)

      # in place memory array content replacement due to some erroneus behaviour with angular
      # while watching the arrays
      filterInvestmentShareClassesBySubDocType = (subDocType) ->
        $scope.filteredInvestmentShareClasses.length = 0
        [].push.apply(
          $scope.filteredInvestmentShareClasses,
          filterForSubDocType(
            $scope.availableInvestmentShareClasses,
            subDocType
          )
        )

      filterForSubDocType = (shareClasses, subDocType) ->
        if subDocType == 0
          _.reject(shareClasses, (sc) -> sc.subscription_type != 0)
        else
          _.reject(shareClasses, (sc) ->
            sc.subscription_type != 0 && sc.subscription_type != subDocType)

      init = ->
        $scope.wlpId = IcnIdentity.data.white_label_partner?.id
        $scope.activeSeries = _.sortBy(_.where($scope.privateAccessFund.series, { disabled: false }), (s) -> s.name)
        $scope.hasSeries = $scope.activeSeries.length > 1
        $scope.investment.amount = $routeParams.commitment
        $scope.subDocCodeValid = !$scope.privateAccessFund.sub_doc_code_required
        $scope.investmentShareClassCodeValid = !$scope.privateAccessFund.investment_share_class_code_required
        $scope.subBooks = $scope.privateAccessFund.library_documents
        $scope.subBooks.$promise.then (docs) ->
          $scope.subBooks = _.reject(docs, (doc) -> doc.hide_on_investment_creation)

          if $routeParams.sub_book_id
            $scope.subBook.selectedItem = _.findWhere(
              $scope.privateAccessFund.library_documents,
              { id: parseInt($routeParams.sub_book_id) }
            )

          if !$scope.requiresInvestmentShareClassCode()
            InvestmentShareClassService.available(
              $scope.privateAccessFund.id,
              IcnIdentity.data.white_label_partner?.id,
              IcnIdentity.user?.firm_id
            ).then((availableShareClasses) ->
              $scope.hasInvestmentShareClasses = availableShareClasses.length > 0
              $scope.availableInvestmentShareClasses =
                _.reject(availableShareClasses, (sc) -> sc.hide_on_investment_creation)

              if $scope.subBook.selectedItem
                filterInvestmentShareClassesBySubDocType($scope.subBook.selectedItem.sub_doc_type_id)
              else if $scope.subBooks.length == 1
                filterInvestmentShareClassesBySubDocType($scope.subBooks[0].sub_doc_type_id)
              else
                filterInvestmentShareClassesBySubDocType(0)

              if $routeParams.investment_share_class_id
                $scope.investmentShareClass.selectedItem =
                  _.findWhere(
                    $scope.filteredInvestmentShareClasses,
                    { id: parseInt($routeParams.investment_share_class_id) }
                  )
            )

        initQuestionnaireForAccount()
        initSupplementalQuestions()

        if $routeParams.sub_book_id
          $scope.privateAccessFund.library_documents.$promise.then (sub_books) ->
            $scope.subBook.selectedItem = _.findWhere(
              $scope.privateAccessFund.library_documents,
              { id: parseInt($routeParams.sub_book_id) }
            )

        if $routeParams.series_id
          $scope.formData.selectedSeries = $routeParams.series_id

        if IcnIdentity.canHaveInvestments()

          InvestorProfileService.mine().$promise.then (d) ->
            $scope.investorProfiles = d.investor_profiles
            if !$scope.showInvestorProfiles || $scope.investorProfiles.length >= 1
              if $scope.investor_profile_id
                ip = _.findWhere($scope.investorProfiles, { id: parseInt($scope.investor_profile_id) })
                $scope.formData.investor_profile = ip
              else if $scope.investorProfiles.length == 1
                $scope.formData.investor_profile = d.investor_profiles[0]


      initQuestionnaireForAccount = ->
        params = {
          private_access_fund_id: $scope.privateAccessFund.id,
          white_label_partner_id: $scope.wlpId,
          firm_id: IcnIdentity.user.firm_id
        }
        PreApprovalQuestionnaireForm.questionnaireExistForInvestor(params).then (response) ->
          $scope.hasQuestionnaireTemplate = response.data
          setQuestionnaireAnswers() unless !$scope.hasQuestionnaireTemplate?.account

      setQuestionnaireAnswers = ->
        localAnswers = window.localStorage.getItem('questionnaire_answers')
        $scope.questionnaire_answers = JSON.parse(localAnswers) unless localAnswers == null
        $scope.questionnaire_answered = $routeParams.questionnaire_answered == 'true'
        setQuestionnaireLinkText()

      setQuestionnaireLinkText = ->
        $scope.questionnaireLinkText =
          if $scope.hasQuestionnaireAnswers()
            'Completed'
          else
            'Click here to complete'

      initSupplementalQuestions = ->
        $scope.supplementalFormAnswers = null
        $scope.supplementalFormAnswered = false
        $scope.supplementalFormLinkText = 'Click here to complete'

        if $scope.privateAccessFund.has_investment_supplemental_form
          $scope.supplementalResponseCompleted = false
        else
          $scope.supplementalResponseCompleted = true

        if $routeParams.supplemental_form_answered == 'true' &&
            window.localStorage.getItem('supplemental_form_answers') != null
          $scope.supplementalResponseCompleted = true
          localAnswers = window.localStorage.getItem('supplemental_form_answers')
          $scope.supplementalFormAnswers = JSON.parse(localAnswers) unless localAnswers == null
          $scope.supplementalFormAnswered = true
          $scope.supplementalFormLinkText = 'Completed'

      $scope.$watch "privateAccessFund", (newVal, oldVal) ->
        if newVal != undefined
          init()

      $scope.$watchGroup ["signMethod", "formData.investor_profile"], ->
        if $scope.custodianManualSignatureOnly($scope.formData.investor_profile) || $scope.signMethod == 'manual_only'
          $scope.btnText = "Download & Sign The Subscription Agreement"
        else
          $scope.btnText = "View & Sign The Subscription Agreement"

      $scope.$watch 'subBook.selectedItem', (newSubDoc) ->
        if newSubDoc?
          $scope.filteredInvestmentShareClasses.length = 0
          [].push.apply(
            $scope.filteredInvestmentShareClasses,
            filterForSubDocType(
              $scope.availableInvestmentShareClasses,
              newSubDoc.sub_doc_type_id
            )
          )

          if !_.include($scope.filteredInvestmentShareClasses,
            $scope.investmentShareClass.selectedItem)
              $scope.investmentShareClass.selectedItem = undefined

      $scope.openSupplementalForm = ($event = undefined) ->
        url = urlBuilder.supplementalFormPreInvestmentUrl(buildSupplementalFormQueryString())

        $location.url url if url
        $event.preventDefault() if $event

      $scope.openQuestionnaire = ($event = undefined) ->
        url = urlBuilder.preApprovalQuestionnaireUrl(buildQuestionnaireQueryString())

        $location.url url if url
        $event.preventDefault() if $event

      buildSupplementalFormQueryString = ->
        queryString = baseFormsQueryString()
        queryString += "&questionnaire_answered=#{$scope.questionnaire_answered || ''}"
        queryString += "&pre_investment=true"
        queryString

      buildQuestionnaireQueryString = ->
        queryString = baseFormsQueryString()
        queryString += "&account_selected=true&wlp_id=#{$scope.wlpId}"
        queryString

      baseFormsQueryString = ->
        "signer_type=#{$scope.formData.investor_profile.signer_type}" +
        "&paf_id=#{$scope.privateAccessFund.id}" +
        "&investor_profile_id=#{$scope.formData.investor_profile.id}" +
        "&sub_book_id=#{$scope.subBook.selectedItem?.id || ''}" +
        "&investment_share_class_id=#{$scope.investmentShareClass.selectedItem?.id || ''}" +
        "&commitment=#{$scope.investment.amount || ''}" +
        "&series_id=#{$scope.formData.selectedSeries || ''}"  +
        "&supplemental_form_answered=#{$scope.supplementalFormAnswered || ''}" +
        "&source_modal=createInvestmentQP"
