angular.module('controllers').controller 'AddUpdateLibraryTemplateDialogCtrl', ($scope, $modal, $modalInstance,
  echosignDocuments, docusignDocuments, mode, template) ->
  $scope.libraryTemplate = {}
  $scope.echosignDocuments = echosignDocuments
  $scope.docusignDocuments = docusignDocuments
  $scope.mode = mode
  $scope.form = {}

  if $scope.mode == 'edit'
    $scope.headerText = 'Update Row'
    $scope.buttonText = 'Update'
    echosignDocument = _.find $scope.echosignDocuments, (doc) ->
      doc.library_document_id == template.library_document_id
    docusignDocument = _.find $scope.docusignDocuments, (doc) ->
      doc.library_document_id == template.docusign_library_document_id
    $scope.libraryTemplate =
      id: template.id
      name: template.name
      echosignDocument: echosignDocument
      docusignDocument: docusignDocument
      prefix: template.prefix
      pageCount: template.page_count
  else
    $scope.headerText = 'Add Row'
    $scope.buttonText = 'Create'

  $scope.createTemplate = ->
    $modalInstance.close($scope.libraryTemplate)

  $scope.cancelDialog = ->
    $modalInstance.dismiss()

  $scope.invalidForm = ->
    !$scope.libraryTemplate.name ||
    (!$scope.libraryTemplate.echosignDocument && !$scope.libraryTemplate.docusignDocument) ||
    !$scope.libraryTemplate.prefix ||
    !$scope.form.template_form.template_page_count.$valid
