angular.module('controllers').controller 'LibraryTemplatesCtrl', ($scope, $modal, $sce, $route, $location,
  LibraryDocumentService, LibraryTemplateService, IcnIdentity, urlBuilder) ->
  $scope.message = null
  $scope.error = null

  if IcnIdentity.canReactLibraryTemplates()
    urlBuilder.reactUrl($location.url())
    return

  LibraryDocumentService.query().$promise.then (docs) ->
    $scope.echosignLibraryDocuments = _.filter(docs, { vendor: 'echosign' })
    $scope.docusignLibraryDocuments = _.filter(docs, { vendor: 'docusign' })

  LibraryTemplateService.query().$promise.then (templates) ->
    $scope.libraryTemplates = templates

  $scope.addRow = ->
    $modal.open(
      templateUrl: 'areas/library_documents_admin/dialogs/add_library_template_row_dialog.html'
      controller: 'AddUpdateLibraryTemplateDialogCtrl'
      windowClass: "small-modal"
      resolve:
        echosignDocuments: -> $scope.echosignLibraryDocuments
        docusignDocuments: -> $scope.docusignLibraryDocuments
        mode: -> 'add'
        template: -> null
    ).result.then (data) ->
      params =
        name: data.name
        echosign_name: data.echosignDocument?.name
        docusign_name: data.docusignDocument?.name
        library_document_id: data.echosignDocument?.library_document_id
        docusign_library_document_id: data.docusignDocument?.library_document_id
        prefix: data.prefix
        page_count: data.pageCount
      LibraryTemplateService.save(params).$promise.then (template) ->
        $scope.message = { text: 'Template added successfully.', type: 'success' }
        $scope.libraryTemplates.push(template)
      , (response) ->
        $scope.message = { items: response.data.message, type: 'danger' }

  $scope.deleteRow = (template) ->
    LibraryTemplateService.delete({id: template.id}).$promise.then (response) ->
      $scope.message = { text: 'Template deleted successfully.', type: 'success' }
      LibraryTemplateService.query().$promise.then (templates) ->
        $scope.libraryTemplates = templates
    , (response) ->
      $scope.message = { items: 'An error occured deleting the template.', type: 'danger' }

  $scope.editRow = (template) ->
    $modal.open(
      templateUrl: 'areas/library_documents_admin/dialogs/add_library_template_row_dialog.html'
      controller: 'AddUpdateLibraryTemplateDialogCtrl'
      windowClass: "small-modal"
      resolve:
        echosignDocuments: -> $scope.echosignLibraryDocuments
        docusignDocuments: -> $scope.docusignLibraryDocuments
        mode: -> 'edit'
        template: -> template
    ).result.then (data) ->
      params =
        id: data.id
        name: data.name
        echosign_name: data.echosignDocument?.name
        docusign_name: data.docusignDocument?.name
        library_document_id: data.echosignDocument?.library_document_id
        docusign_library_document_id: data.docusignDocument?.library_document_id
        prefix: data.prefix
        page_count: data.pageCount
      LibraryTemplateService.update(params).$promise.then (template) ->
        $scope.message = { text: 'Template updated successfully.', type: 'success' }
        LibraryTemplateService.query().$promise.then (templates) ->
          $scope.libraryTemplates = templates
      , (response) ->
        $scope.message = { items: response.data.message, type: 'danger' }

  $scope.dismissMessage = ->
    $scope.message = null

  $scope.messageIsType = (type) ->
    $scope.message && $scope.message.type == type

  $scope.refreshTemplates = ->
    LibraryDocumentService.refreshTemplates()
