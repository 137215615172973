angular.module('directives').directive "icnPafSummary", ->
  restrict: "E"
  scope:
    privateAccessFund: "="
    user: "="
  templateUrl: "areas/private_equity_funds/private_access_funds/components/paf_summary/summary.html"
  controller: ($scope, Funds, FundDocuments, IcnIdentity) ->
    loadFund = ->
      Funds.find($scope.privateAccessFund.fund_id).then (data) ->
        $scope.fund = data

      $scope.defaultInstructionalText = "Thank you for your interest in iCapital Network's #{$scope.privateAccessFund.name}. Prior to proceeding to step 2, please ensure you have reviewed the Access Fund profile, summary term sheet and related documents."

    loadFundDocuments = ->
      FundDocuments.all($scope.privateAccessFund.fund_id).then (data) ->
        $scope.fundDocuments = data

    $scope.$watch "privateAccessFund", (newVal) ->
      return  if !!newVal is false
      loadFund()
      loadFundDocuments()
