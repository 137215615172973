angular.module('controllers').controller "sendDocumentsToIntegrationPartnerDialogCtrl",
  ($scope, $modal, $modalInstance, $window, investment, openFund, RequiredDocument, AmlApprovalDocumentService,
  InvestmentService, IntegrationPartnerService) ->
    $scope.investment = investment
    $scope.isClosedFund = (openFund == false)
    $scope.integrationPartnerOptions = []
    $scope.integrationPartner = []

    $scope.multiSelectSettings = {
      enableSearch: true
      displayProp: 'name'
      checkBoxes: true
      clearSearchOnClose: true
    }

    IntegrationPartnerService.forPaf(
      privateAccessFundId: investment.private_access_fund_id
    ).$promise.then((res) ->
      if res.integration_partners.length == 1
        $scope.integrationPartner = res.integration_partners
        $scope.integrationPartnerText = {
          buttonDefaultText: $scope.integrationPartner[0].name
          dynamicButtonTextSuffix: $scope.integrationPartner[0].name
        }
        $scope.multiSelectSettings.smartButtonMaxItems = 1
      else
        $scope.integrationPartnerText = {
          buttonDefaultText: 'Integration Partners'
          dynamicButtonTextSuffix: 'Integration Partners'
        }
      $scope.integrationPartnerOptions = res.integration_partners
      return
    )

    $scope.selectAllCheckbox = {
      id: 'selectAllDocs'
      label: 'All'
      selected: false
      }

    $scope.cancelDialog = ->
      $modalInstance.dismiss()

    $scope.sendDocs = ->
      docsIds = selectedDocs().map (doc) ->
        doc.id = doc.document_id

      integrationPartnerIds = $scope.integrationPartner.map((itm) -> itm.id)

      InvestmentService.sendDocumentsToIntegrationPartner($scope.investment.id, docsIds,integrationPartnerIds)
        .then (response) ->
          $modal.open(
            templateUrl: "dialogs/custom_notify_dialog/custom_notify_dialog.html"
            controller: "customNotifyDialogCtrl"
            windowClass: "small-modal"
            resolve:
              header: ->
                "Documents Sent"

              msg: ->
                "The document package has been sent to the integration partner for this investment"

              btn_label: -> ""
          ).result.then ->
            $scope.cancelDialog()
          .catch ->
            $scope.cancelDialog()


    $scope.viewDocument = (document) ->
      agreement_id = document.echosign_agreement_id
      vendor = document.signature_vendor

      if agreement_id
        ip_id = $scope.investment.investor_profile_id

        if ip_id
          url = "/api/v1/echosign/agreements/#{agreement_id}?investor_profile_id=#{ip_id}&vendor=#{vendor}"
        else
          url = "/api/v1/echosign/agreements/#{agreement_id}?vendor=#{vendor}"

        url += "&agreement_type=aml"
        $window.open url
      else
        RequiredDocument.view(
          id: document.document_id
        ).$promise.then (res) ->
          $window.open res.url

    $scope.$watch "selectAllCheckbox.selected", ((newVal, oldVal) ->
      checkAll(newVal)
    )

    selectedDocs = ->
      _.filter($scope.documents, (doc) -> doc.selected)

    $scope.disableSendDocs = ->
      selectedDocs().length <= 0 || $scope.integrationPartner.length <= 0

    checkAll = (selected) ->
      _.each($scope.documents, (el) -> el.selected = selected)

    init = ->
      AmlApprovalDocumentService.query({investment_id: $scope.investment.id}).$promise.then (data) ->
        $scope.documents = data
        $scope.documents = $scope.documents.map (doc) ->
          doc.selected = false
          doc

    init()
