angular.module('directives').directive "pafTopNav", ->
  restrict: "E"
  scope:
    privateAccessFund: "="
    user: "="

  controller: ($scope, $routeParams) ->
    $scope.steps =
      summary: 1
      legal_docs: 2
      investment_status: 3
      client_management: 3

    $scope.currentStep = $routeParams.action or "summary"
    return

  templateUrl: "areas/private_equity_funds/private_access_funds/components/top_nav/_top_nav.html"
