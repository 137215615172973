angular.module('controllers').controller "PrivateAccessFundsCtrl",
  ($scope, $routeParams, $location, $rootScope, $sce, PrivateAccessFunds,
   IcnIdentity, DisclaimerService, InvestmentService, ClientManagementService) ->
    $scope.currentStep = $routeParams.action or "summary"
    data = IcnIdentity.data
    $scope.user = data.user
    $scope.subDocCreated = ->
      $scope.investmentCreated = true

    redirectIfLimited = ->
      return if !limited()
      if IcnIdentity.defaultPage
        $location.path(IcnIdentity.defaultPage)
      else
        history.back()

    limited = ->
      IcnIdentity.isLimitedForPaf($scope.privateAccessFund.id) || $scope.privateAccessFund.details_hidden

    loadPrivateAccessFund = ->
      PrivateAccessFunds.get
        id: $routeParams.id
      , (data) ->
        $scope.privateAccessFund = data.private_access_fund
        $scope.privateAccessFund.customTermsHTML = $sce.trustAsHtml($scope.privateAccessFund.custom_terms)

        InvestmentService.all($scope.privateAccessFund.id).$promise.then (investments) ->
          $scope.investments = investments

        redirectIfLimited()
        if $scope.currentStep == 'client_management'
          ClientManagementService.setPafId(parseInt($routeParams.id))

    init = ->
      loadPrivateAccessFund()

    init()
