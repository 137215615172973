angular.module('directives').directive "pafBottomNav", ($location) ->
  restrict: "E"
  scope:
    privateAccessFund: "="
    user: "="
    investments: "="
    investmentCreated: "="
  templateUrl: "areas/private_equity_funds/private_access_funds/components/paf_bottom_nav/_bottom_nav.html"
  link: (scope) ->
    scope.returnToFund = ->
      $location.path("/funds/#{scope.privateAccessFund.fund_id}")
