import { shellUi } from '@icapitalnetwork/shell-ui';

var app = angular.module('icn', [
  'controllers',
  'directives',
  'services',
  'filters',
  'ngRoute',
  'ngGrid',
  'ngCkeditor',
  'ngSanitize',
  'ui.event',
  'ui.highlight',
  'ui.date',
  'ui.mask',
  'ui.bootstrap.tpls',
  'ui.bootstrap.popover',
  'ui.bootstrap.tooltip',
  'ui.bootstrap.typeahead',
  'ui.bootstrap.accordion',
  'ui.bootstrap.datepicker',
  'ui.bootstrap.transition',
  'ui.bootstrap.modal',
  'ui.bootstrap.collapse',
  'ui.bootstrap.buttons',
  'ui.bootstrap.pagination',
  'frapontillo.ex.filters',
  'angular.filter',
  'angularFileUpload',
  'ngDialog',
  'angularMoment',
  'ui.select',
  'toggle-switch',
  'ngCookies',
  'frapontillo.bootstrap-switch',
  'ui.validate',
  'textAngular',
  'focus-if',
  'dialogs',
  'validator',
  'validator.rules',
  'ngStorage',
  'icn.paf_admin',
  'sticky',
  'icn.models',
  'icn.components',
  'ngCsv',
  'ui.utils.masks',
  'ngFileSaver',
  'angularjs-dropdown-multiselect',
  'ui-notification',
]);

app.config(function (
  $validatorProvider,
  $tooltipProvider,
  NotificationProvider
) {
  $validatorProvider.register('requiredBlur', {
    invoke: 'blur',
    validator: /^.+$/,
    error: 'This field is required.',
  });

  $validatorProvider.register('requiredBlurNoMessage', {
    invoke: 'blur',
    validator: /^.+$/,
    error: '',
  });

  $validatorProvider.register('numberSubmit', {
    validator: /^[\-+]?[0-9]*[\.]?[0-9]*$/,
    error: 'This field should be number.',
  });

  $validatorProvider.register('emailFormat', {
    invoke: 'watch',
    validator:
      /^$|^[A-Za-z0-9]{1}(?!.*?\.\.)[^ @]*@[A-Za-z0-9\-.]+\.[A-Za-z]+$/,
    error: 'Invalid email format.',
  });

  $validatorProvider.register('loginFormat', {
    invoke: 'blur',
    validator: /^$|^[A-Za-z0-9._%+\-]+@[A-Za-z0-9.\-]+\.[A-Za-z]{2,}\#[0-9]+$/,
    error: 'Invalid login format.',
  });

  $validatorProvider.register('yearFormat', {
    invoke: 'blur',
    validator: /^\d{4}$/,
    error: 'Wrong year format.',
  });

  $validatorProvider.register('ssnFormat', {
    invoke: 'blur',
    validator: /^\d{3}-?\d{2}-?\d{4}$/,
    error: 'Wrong SSN format (xxx-xx-xxxx).',
  });

  $validatorProvider.register('zipFormat', {
    invoke: 'blur',
    validator: /^\d{5}$/,
    error: 'Wrong Zipcode format (xxxxx).',
  });

  $validatorProvider.register('abaFormat', {
    invoke: 'blur',
    validator: /^\d{9}$/,
    error: 'Wrong ABA format. xxxxxxxxx.',
  });
  $validatorProvider.register('phoneFormat', {
    invoke: 'blur',
    validator: /^\d{3}-{0,1}\d{3}-{0,1}\d{4}$/,
    error: 'Must be a valid phone number. xxx-xxx-xxxx',
  });
  $validatorProvider.register('password', {
    invoke: 'blur',
    validator: /^(?=.*[A-Za-z])(?=.*\d).{10,}$/,
    error:
      'Password must be at least 10 characters and contain at least an uppercase letter, ' +
      'lowercase letter, number and special character.',
  });
  $validatorProvider.register('passwordConfirmation', {
    invoke: 'blur',
    validator: function (value, scope, element, attrs, $injector) {
      return scope.user.password == scope.user.password_confirmation;
    },
    error: 'Confirmed password does not match',
  });
  $validatorProvider.register('percentage', {
    invoke: 'watch',
    validator: /^0*(?:[0-9][0-9]?(?:\.[0-9]+)?|100)$/,
    error: 'Value must be a percentage between 0 and 100.',
  });
  $validatorProvider.register('month', {
    invoke: 'blur',
    validator: /^01|02|03|04|05|06|07|08|09|10|11|12$/,
    error: 'Month must be a two digit value between 01 and 12',
  });
  $validatorProvider.register('year', {
    invoke: 'blur',
    validator: function (value, scope, element, attrs, $injector) {
      val = parseInt(value);
      return val > 1899;
    },
    error: 'Year must be greater than 1900',
  });
  $validatorProvider.register('day', {
    invoke: 'blur',
    validator: /^(0[1-9]{1}|1[0-9]{1}|2[0-9]{1}|30|31){1}$/,
    error: 'Day must be a two digit value between 01 and 31',
  });

  $tooltipProvider.options({
    appendToBody: true,
    showCallback: function () {
      $(this).trigger('showTooltip');
    },
  });

  NotificationProvider.setOptions({
    delay: 5000,
    templateUrl: 'dialogs/notification/notification.html',
    startTop: 100,
    startRight: 50,
  });
});

app.run(function (
  $rootScope,
  $injector,
  $location,
  $window,
  ModalDialogInstancesObserver
) {
  $rootScope.routeLoading = true;
  $rootScope.currentUrl = null;

  $rootScope.$on('$routeChangeStart', function (events, next) {
    $rootScope.currentRoute = next;

    if ($window.MiniProfiler) {
      $window.MiniProfiler.pageTransition();
    }

    // Injector needed, because passing via function breaks tests
    var Redirects = $injector.get('Redirects');
    Redirects.redirectIfNecessary();
  });

  $rootScope.$on('$routeChangeSuccess', function (events, next) {
    if ($window.gtag) {
      $window.gtag('config', $window.gaProperty, {
        page_path: $location.url(),
      });
    }
  });

  $rootScope.$on('$locationChangeSuccess', function (event, current) {
    $rootScope.routeLoading = false;
    $rootScope.currentUrl = current;
  });

  // when navigate out of current page, dismiss modal box instances
  $rootScope.$on('$locationChangeStart', function (event, newUrl, oldUrl) {
    // get all current active modal box instance
    var modalInstances = ModalDialogInstancesObserver.getModalDialogInstance();
    // dismiss them all
    if (modalInstances.length) {
      for (var i = 0; i < modalInstances.length; i++) {
        modalInstances[i].dismiss('cancel');
      }
      // clear stored instances
      ModalDialogInstancesObserver.clearAllModalDialogInstances();
    }
  });
});

// Components (actual "component" syntax components)

let MainContentComponent = {
  selector: 'mainContent',
  templateUrl: 'templates/components/main_content.html',
  transclude: true,
  controller: [
    'IcnIdentity',
    '$location',
    '$rootScope',
    function MainContentController(IcnIdentity, $location, $rootScope) {
      var ctrl = this;

      ctrl.isMainSite = () => IcnIdentity.data.white_label_partner.id == 0;

      ctrl.loggedIn = () => IcnIdentity.authenticated();

      ctrl.containerFullWidth = () =>
        IcnIdentity.data.white_label_partner.slug == 'ieqc';

      // About to refresh to the new login page (triggered from login.coffee)
      ctrl.shouldLoadNewLoginPage = () =>
        !ctrl.loggedIn() &&
        IcnIdentity.whiteLabelPartner().enable_new_login_page &&
        ($location.path() === '/' || $location.path() === '/login');

      if (ctrl.shouldLoadNewLoginPage()) {
        $rootScope.routeLoading = true;
        return;
      }

      ctrl.enableSitenavMFE = () =>
        IcnIdentity.whiteLabelPartner().enable_sitenav_mfe;

      const MFEs = [];

      if (ctrl.enableSitenavMFE() && ctrl.loggedIn()) {
        MFEs.push([
          '@icapitalnetwork/sitenav',
          {
            entry: '/apps/unified-sitenav/174-0-14/spa.js',
            activeWhen: '/',
            customProps: (name, location) => ({
              // Sitenav-specific customProps
              mode: ['icn'],
              isFixedTop:
                location.pathname.startsWith('/icn_react/static/funds') ||
                location.pathname.startsWith(
                  '/icn_react/static/product_profile_builder/fund_profile'
                ),
              isAngularHost: true,
            }),
          },
        ]);
      }

      // Nothing to load
      if (!MFEs.length) return;

      this.$onInit = function () {
        shellUi({
          devMode: process.env.NODE_ENV !== 'production',
          customProps: {
            routerMode: 'history',
            bootstrapData: { icn_bootstrap: IcnIdentity.data },
            host: 'icn',
            auth: {
              issuer: IcnIdentity.data.okta.issuer,
              clientId: IcnIdentity.data.okta.clientId,
              redirectUri: '/',
              loginPathname: '/login',
            },
          },
          manifest: Object.fromEntries(MFEs),
        });
      };
    },
  ],
};
app.component(MainContentComponent.selector, MainContentComponent);

let HeaderContainerComponent = {
  selector: 'headerContainer',
  templateUrl: 'templates/components/header_container.html',
  transclude: true,
  require: {
    mainCtrl: '^mainContent',
  },
  controller: [
    'IcnIdentity',
    function HeaderContainerController(IcnIdentity) {
      var ctrl = this;
      ctrl.environmentLabel = IcnIdentity.environment();
      ctrl.pendingMfaRegistration = IcnIdentity.pendingMfaRegistration();
      ctrl.headerLogoSrc = IcnIdentity.data.header_logo;
      ctrl.loggedIn = () => ctrl.mainCtrl.loggedIn();
    },
  ],
};
app.component(HeaderContainerComponent.selector, HeaderContainerComponent);

let LoginBannerComponent = {
  selector: 'loginBanner',
  templateUrl: 'templates/components/login_banner.html',
  transclude: true,
  require: {
    mainCtrl: '^mainContent',
  },
  controller: [
    'IcnIdentity',
    function LoginBannerController(IcnIdentity) {
      var ctrl = this;
      ctrl.bannerText = IcnIdentity.data.white_label_partner.banner?.message;
      ctrl.publicLogoSrc = IcnIdentity.data.public_logo;

      ctrl.isMainSite = () => ctrl.mainCtrl.isMainSite();

      ctrl.showWlpLogo = () => {
        return (
          IcnIdentity.data.white_label_partner.banner_logo_display_option !=
          'no_logo'
        );
      };

      ctrl.getBannerTextColSize = () => {
        let banner_width =
          IcnIdentity.data.white_label_partner.banner?.message_content_width;
        banner_width ||= 5;
        return banner_width;
      };

      ctrl.getBannerBackgroundInlineStyles = () => {
        return IcnIdentity.data.white_label_partner.banner
          ?.banner_background_inline_styles;
      };
    },
  ],
};
app.component(LoginBannerComponent.selector, LoginBannerComponent);

let ImpersonationHeaderComponent = {
  selector: 'impersonationHeader',
  templateUrl: 'templates/components/impersonation_header.html',
  transclude: true,
  require: {
    mainCtrl: '^mainContent',
  },
  controller: [
    'IcnIdentity',
    'Users',
    '$location',
    '$route',
    '$window',
    function ImpersonationHeaderController(
      IcnIdentity,
      Users,
      $location,
      $route,
      $window
    ) {
      var ctrl = this;
      ctrl.userDisplayName = IcnIdentity.data.user.name;

      ctrl.impersonateText = () => {
        if (
          IcnIdentity.data.user.is_delegated ||
          IcnIdentity.data.user.is_investor_role
        ) {
          return 'You are acting on behalf of';
        } else if (IcnIdentity.data.user.is_imposter) {
          return 'You are impersonating';
        }
      };

      ctrl.stopImpersonateText = () => {
        if (IcnIdentity.data.user.is_delegated) {
          return 'Stop Acting as Delegate';
        } else if (IcnIdentity.data.user.is_investor_role) {
          return 'Stop Acting as a Representative';
        } else if (IcnIdentity.data.user.is_imposter) {
          return 'Stop Impersonating';
        }
      };

      ctrl.stopImpersonate = () => {
        Users.stop_impersonate(IcnIdentity.user.id).then((response) => {
          let url = '/icn_react/static/impersonation';

          if (IcnIdentity.data.user.is_investor_role) {
            url = '/investor_representatives';
          }

          $window.location.href = url;
          $location.path(url);
          $route.reload();
        });
      };

      ctrl.loggedIn = () => ctrl.mainCtrl.loggedIn();

      ctrl.currentlyImpersonating = () => IcnIdentity.data.user.is_imposter;
    },
  ],
};
app.component(
  ImpersonationHeaderComponent.selector,
  ImpersonationHeaderComponent
);

let BannerTextComponent = {
  selector: 'bannerText',
  // templateUrl: "templates/components/banner_text.html",
  // This is super unsafe, but I can't find a better alternative that lets me use HTML content from text editor with
  // angularJS $sanitize without it removing 'style' tags
  // Note: It is as (un)safe as previous option, which used Rails 'html_safe' on the layout
  template: function (tElement, tAttrs) {
    var html = '<div class="banner-text">';
    var banner_msg = window.icnBootstrap.white_label_partner.banner?.message;
    if (banner_msg) {
      html += banner_msg;
    }
    html += '<div class="banner-text">';
    return html;
  },
  controller: [
    'IcnIdentity',
    '$sce',
    function BannerTextController(IcnIdentity, $sce) {
      var ctrl = this;
      ctrl.bannerText = $sce.trustAsHtml(
        IcnIdentity.data.white_label_partner.banner?.message
      );
    },
  ],
};
app.component(BannerTextComponent.selector, BannerTextComponent);
