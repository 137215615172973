angular.module('services').factory 'InvestmentDetailRouter', ($location, $dialogs, $modal, IcnIdentity, InvestorProfileService, InvestmentService, ClientManagementService, urlBuilder) ->

  user = IcnIdentity.user
  wlp = IcnIdentity.data.white_label_partner
  isRiaOrBranchManager = IcnIdentity.isRiaOrBranchManager()

  perform = (paf) ->
    if user.cooled_off || user.coolOffPafsOverrides.indexOf(paf.id) > -1 || user.coolOffPafsOverrides.indexOf(null) > -1
      if isRiaOrBranchManager
        InvestmentService.userPafInvestmentsCount(paf.id).then (response) ->
          if _.contains(user.purchase_path_pages_viewed.summary_terms, paf.id)
            ClientManagementService.setBoth(undefined, undefined)
            if IcnIdentity.user.canReactClientManagement
              url = "/client_management/#{paf.id}?source_modal=createInvestment"
              urlBuilder.reactUrl(url)
            else
              $location.path("/client_management/#{paf.id}").search(createInvestment: true)
          else
            $location.path("/private_access_funds/#{paf.id}")
      else if wlp?.hide_investor_profiles
        $location.path("/private_access_funds/#{paf.id}")
      else
        InvestorProfileService.profileCompleted().$promise.then( (response) ->
          if (response.is_completed)
            $location.path("/private_access_funds/#{paf.id}")
          else
            newIssues = if paf.open_ended then 'true' else 'false'
            profileId = response.investor_profile_id || 'new_profile'
            url = urlBuilder.investorProfileUrl(profileId, "1")
            $location.url if url

        )
    else
      if user.isRia
        header = "Unable to Invite"
        msg = "You are not allowed to invite investors yet.  There are #{user.cooled_off_days_remaining} " +
          "days left until the end of your firm's cooling off period."
      else
        header = "Unable to Invest"
        if user.cooled_off_days_remaining == 1
          remaining_days_string = "is #{user.cooled_off_days_remaining} day"
        else
          remaining_days_string = "are #{user.cooled_off_days_remaining} days"

        msg = "You are not allowed to invest yet." +
              " There #{remaining_days_string} until the end of your cooling off period."

      $modal.open
        templateUrl: "dialogs/custom_notify_dialog/custom_notify_dialog.html"
        controller: "customNotifyDialogCtrl"
        windowClass: "small-modal"
        resolve:
          header: -> header
          msg: -> msg
          btn_label: -> ""
          custom_body_class: -> 'no-left-padding-body'

  buttonText = ->
    "Create Investment"


  shortButtonText = ->
    "Create Investment"


  return {
    perform: perform
    buttonText: buttonText
    shortButtonText: shortButtonText
  }
