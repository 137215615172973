angular.module('directives').directive "prevNext", ->
  restrict: "E"
  scope:
    privateAccessFund: "="
    user: "="
    investments: "=?"
    investmentCreated: "=?"

  controller: ($scope, $routeParams, $location, Users, IcnIdentity, ClientManagementService, urlBuilder) ->
    $scope.blockNext = ->
      return true if $scope.nextStep == undefined
      !$scope.investmentCreated && $scope.currentStep == "legal_docs"

    $scope.steps = []
    $scope.currentStep = $routeParams.action or ""
    $scope.newIssues = $routeParams.newIssues == 'true'
    skipLegalDocs = false
    isRiaOrBranchManager = IcnIdentity.isRiaOrBranchManager()
    nonRiaInvestor = IcnIdentity.user.isQP or
      IcnIdentity.user.isSfo or
      IcnIdentity.user.isAi or
      IcnIdentity.user.isAicl

    determineNextSteps = ->
      if isRiaOrBranchManager
        $scope.steps = [
          ""
          "client_management"
        ]
      else if nonRiaInvestor
        $scope.steps = [
          ""
          "legal_docs"
          "investment_management"
        ]

    loadUserSteps = ->
      determineNextSteps()
      currentStepIndex = _.indexOf($scope.steps, $scope.currentStep)
      $scope.previousStep = $scope.steps[currentStepIndex - 1]  if currentStepIndex > 0
      $scope.nextStep = $scope.steps[currentStepIndex + 1]  if currentStepIndex < $scope.steps.length - 1
      return

    $scope.$watch "user", (newValue) ->
      return  if !!newValue is false
      loadUserSteps()
      return

    $scope.nextPage = (path) ->
      if skipLegalDocs('')
        path = "/investment_management/#{$scope.privateAccessFund.id}"
      else
        path = "/private_access_funds/#{$scope.privateAccessFund.id}/#{$scope.nextStep}"
      # Override path to investment management page
      if $scope.nextStep == 'investment_management'
        ClientManagementService.setPafId($scope.privateAccessFund.id)
        path = "/#{$scope.nextStep}/#{$scope.privateAccessFund.id}"

      if $scope.nextStep == "client_management"
        ClientManagementService.setPafId($scope.privateAccessFund.id)
        if IcnIdentity.user.canReactClientManagement
          url = "/client_management/#{$scope.privateAccessFund.id}?source_modal=createInvestment"
          urlBuilder.reactUrl(url)
        else
          $location.path("/client_management/#{$scope.privateAccessFund.id}").search(createInvestment: true)
      else
        $location.path(path)

      if $scope.currentStep == '' && IcnIdentity.user.isRia
        Users.purchasePathPageViewed(IcnIdentity.user.id, 'summary_terms', $scope.privateAccessFund.id)

    $scope.prevPage = (path) ->
      if $scope.previousStep == "client_management"
        if IcnIdentity.user.canReactClientManagement
          url = "/client_management/#{paf.id}?source_modal=createInvestment"
          urlBuilder.reactUrl(url)
        else
          $location.path("/client_management/#{$scope.privateAccessFund.id}").search(createInvestment: true)
      else
        path = "/private_access_funds/#{$scope.privateAccessFund.id}/#{$scope.previousStep}"
        $location.path(path)

    userWontSkipLegal = ->
      # These user roles should not skip the legal docs as their process involves
      # the full 3 steps in creating investments in a PAF:
      # 1) Summary Terms page 2) Legal Documents Page 3) Investment Management Page
      IcnIdentity.user.isAicl ||
      IcnIdentity.user.isQp ||
      IcnIdentity.user.isAi ||
      IcnIdentity.user.isSfo ||
      IcnIdentity.user.isQpcl

    getInProgressAgreements = (investments) ->
      agreements = _.filter(investments, (i) ->
        !_.contains(['canceled','signed', 'not sent'], i.status)
      )
      return agreements


    electronicSubdocMissing = (inProgressAgreements) ->
      electronic_subdoc_present = _.any inProgressAgreements, (agreement) ->
        !agreement.manual_signature
      if electronic_subdoc_present then false else true

    skipLegalDocs = (currentStep) ->
      if userWontSkipLegal()
        false
      else
        if $scope.investments && $scope.currentStep == currentStep

          inProgressAgreements = getInProgressAgreements($scope.investments)
          if inProgressAgreements.length > 0
            electronicSubdocMissing(inProgressAgreements)

        else
          false



        if $scope.investments && $scope.currentStep == currentStep

          if inProgressAgreements.length > 0
            electronic_subdoc_present = _.any inProgressAgreements, (agreement) ->
              !agreement.manual_signature
            if electronic_subdoc_present then false else true
        else
          false

  templateUrl: "areas/private_equity_funds/private_access_funds/components/prev_next/_prev_next.html"
