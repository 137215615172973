angular.module('directives').directive "icnPafLegalDocs", ->
  restrict: "E"
  scope:
    privateAccessFund: "="
    user: "="
    investments: "="
    subDocCreated: "&"
    signMethod: "="
  templateUrl: "areas/private_equity_funds/private_access_funds/components/paf_legal_docs/legal_docs.html"
  controller:
    ($scope, $rootScope, $sce, $timeout, $location, $routeParams, $window, InvestmentService,
      IcnIdentity, IcnUtility, $modal, LibraryDocumentService, urlBuilder, AgreementEmbeddedExperience) ->

      $scope.investorDisplayName = (investment) ->
        if investment.entity_name.length > 0
          investment.entity_name
        else
          investment.contact_card.display_name

      $scope.createInvestment = (commitment, ip_id, sub_book_id, sub_doc_code, investment_share_class_id, series_id,
        supplemental_responses, questionnaire_answers, external_add_on) ->
          InvestmentService.create($scope.privateAccessFund.id,
            investor_profile_id: ip_id
            sub_book_id: sub_book_id
            sub_doc_code: sub_doc_code
            investment_share_class_id: investment_share_class_id
            series_id: series_id
            questionnaire_answers: questionnaire_answers
            contact_card_attributes:
              email: $scope.user.email
            supplemental_responses: supplemental_responses
            commitment: IcnUtility.unformatCurrency(commitment)
            ria_id: IcnIdentity.user.id
            external_add_on: external_add_on
          ).$promise.then (response) ->
            investment = response.investment
            $location.search('supplemental_form_answered', null)
            $location.search('questionnaire_answers', null)
            $location.search('commitment', null)
            $location.search('investor_profile_id', null)
            $location.search('sub_book_id', null)
            $location.search('series_id', null)
            window.localStorage.removeItem('questionnaire_answers') unless !questionnaire_answers
            window.localStorage.removeItem('supplemental_form_answers') unless !supplemental_responses
            $scope.subDocCreated()

            if investment.manual_signature
              $window.open("#{investment.subscription_temp_file_url}")
              $location.url("/investment_management/#{$scope.privateAccessFund.id}")
            else
              AgreementEmbeddedExperience.handleSigningUrl(
                $scope.privateAccessFund.id,
                investment.subscription_document.id,
                "investment_management",
              )

      $scope.$watch "privateAccessFund", (newPaf) ->
        return unless !!newPaf
        $scope.privateAccessFund.library_documents = LibraryDocumentService.forPaf(private_access_fund_id: newPaf.id)
