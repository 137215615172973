angular.module('controllers').controller 'SubscriptionDocumentsAdminCtrl', ($scope, $sce,
  $location, SubscriptionDocumentDefinitionService) ->
  $scope.message = null
  $scope.error = null

  SubscriptionDocumentDefinitionService.query().$promise.then (definitions) ->
    $scope.subdocDefinitions = definitions

  $scope.addNew = ->
    $location.url '/subscription_document_templates/add_definition'

  $scope.hasInvestments = (definition) ->
    definition.has_investments

  $scope.deleteRow = (definition) ->
    SubscriptionDocumentDefinitionService.delete({id: definition.id}).$promise.then (response) ->
      $scope.message = { text: 'Definition deleted successfully.', type: 'success' }
      SubscriptionDocumentDefinitionService.query().$promise.then (definitions) ->
        $scope.subdocDefinitions = definitions
    , (response) ->
      $scope.message = { text: 'An error occured deleting the definition.', type: 'danger' }

  $scope.editRow = (definition) ->
    $location.url "/subscription_document_templates/edit_definition/#{definition.id}"

  $scope.dismissMessage = ->
    $scope.message = null
