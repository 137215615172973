angular.module('services').factory 'IcnUtility', ->
  parseDate = (dateString) ->
    return null if !dateString
    match = dateString.match(/(\d{1,2})\/(\d{1,2})\/(\d{4})/)
    return null if !match
    arr = match.splice 1
    new Date parseInt(arr[2]), parseInt(arr[0]) - 1, parseInt(arr[1])

  # Returns most recent date in arr specified by key (ex: wire_date) plus n days
  # Returns n days from today if arr is empty
  nDaysFromLast = (arr, key, n) ->
    lastItem = _.max arr, (item) -> if key then item[key] else item
    lastDate = if !_.isEmpty(arr) then (if key then lastItem[key] else lastItem) else new Date()
    new Date(lastDate.getTime() + 1000*60*60*24*n)

  nDaysFromLastFormatted = (arr, key, n) ->
    formatDate(nDaysFromLast(arr, key, n))

  maxDate = (arr, key) ->
    return null if _.isEmpty arr
    item = _.max arr, (item) -> if key then item[key] else item
    return if key then item[key] else item

  formatDate = (date) ->
    if typeof date == "string"
      date = new Date(date)
    "#{date.getMonth() + 1}/#{date.getDate()}/#{date.getFullYear()}"

  formatUploadDate = (date) ->
    "#{date.getFullYear()}-#{date.getMonth() + 1}-#{date.getDate()}"

  dateFromMilliseconds = (milliseconds) ->
    new Date(milliseconds)

  unformatCurrency = (value) ->
    value.replace(/\$|,/g, "")

  formatCurrency = (amount) ->
    return amount if _.isNumber(amount)
    amount = this.unformatCurrency(amount)
    if amount == '' then 0 else parseInt(amount)

  roundMoney = (val, precision = 2) ->
    val.toFixed(precision) * 1

  fileExtFromUrl = (url) ->
    if not url? or not url?
      "n/a"
    else
      fileName = _.last(url.toLowerCase().split("/"))
      extension = _.last(fileName.split("."))
      if extension?
        extension.substring 0, extension.length
      else
        ""

  fileTypeFromUrl = (url) ->
    ext = fileExtFromUrl(url)
    return "doc"  if _.contains([
      "doc"
      "docx"
    ], ext)
    return "xls"  if _.contains([
      "xls"
      "xlsx"
    ], ext)
    return "ppt"  if _.contains([
      "ppt"
      "pptx"
    ], ext)
    return "pdf"  if _.contains(["pdf"], ext)
    "other"

  ngGridFlexibleHeightPlugin = (opts) ->
    self = this
    self.grid = null
    self.scope = null
    self.init = (scope, grid, services) ->
      self.domUtilityService = services.DomUtilityService
      self.grid = grid
      self.scope = scope
      recalcHeightForData = ->
        setTimeout innerRecalcForData, 1

      innerRecalcForData = ->
        gridId = self.grid.gridId
        footerPanelSel = "." + gridId + " .ngFooterPanel"
        extraHeight = self.grid.$topPanel.height() + $(footerPanelSel).height()
        naturalHeight = self.grid.$canvas.height() + 1
        if opts? and opts.minHeight? and (naturalHeight + extraHeight) < opts.minHeight
          naturalHeight = opts.minHeight - extraHeight - 2
        newViewportHeight = naturalHeight + 2
        if not self.scope.baseViewportHeight or self.scope.baseViewportHeight isnt newViewportHeight
          self.grid.$viewport.css "height", newViewportHeight + "px"
          self.grid.$root.css "height", (newViewportHeight + extraHeight) + "px"
          self.scope.baseViewportHeight = newViewportHeight
          self.domUtilityService.UpdateGridLayout self.scope, self.grid

      self.scope.catHashKeys = ->
        hash = ""
        idx = undefined
        for idx of self.scope.renderedRows
          hash += self.scope.renderedRows[idx].$$hashKey
        hash

      self.scope.$watch "catHashKeys()", innerRecalcForData
      self.scope.$watch self.grid.config.data, recalcHeightForData
    return

  hasNoValue = (i) ->
    i == undefined || i == '' || i == null

  isChrome = ->
    winNav = window.navigator
    isChromium = window.chrome
    isIEedge = winNav.userAgent.indexOf("Edge") > -1
    isIOSChrome = winNav.userAgent.match("CriOS")
    isOpera = winNav.userAgent.indexOf("OPR") > -1
    vendorName = winNav.vendor

    isDesktopChrome = ->
      isChromium != null && typeof isChromium != "undefined" &&
      vendorName == "Google Inc." && isOpera == false && isIEedge == false

    if isIOSChrome || isDesktopChrome() then true else false

  getPartnerProxyAPIPrefix = ->
    window.partnerProxyAPIPrefix

  hasPartnerProxyAPIPrefix = ->
    prefix = getPartnerProxyAPIPrefix()
    _.isString(prefix) && prefix != ''

  disableThirdPartyJs = ->
    prefix = getPartnerProxyAPIPrefix()
    _.isString(prefix) && prefix != ''

  ensureStartingSlash = (configUrl) ->
    # If the URL passed does not begin with a slash, add it
    # Some requests for directive templates seem to omit the beginning slash
    url = configUrl
    if !url.match(/^\//)
      url = '/' + configUrl
    url

  ensureRelative = (configUrl) ->
    # If the URL begins with a slash, remove it
    url = configUrl
    if url.match(/^\//)
      url = configUrl.replace(/\//,'')
    url

  # AngularJS will use the value stored in <base href="" /> as the base URL
  prependPartnerProxyAPIPrefix = (url) ->
    # when there is a partner prefx, trim starting slash of url passed
    # as we are joining a base url e.g. /partner/prefix/ with /api/v1/foo (url passed)
    return ensureRelative(url)

  # we rely on the config.cache property AngularJS adds to template requests
  # to distinguish from API requests
  isTemplateRequest = (config) ->
    !_.isUndefined(config.cache)

  return {
    parseDate: parseDate
    nDaysFromLast: nDaysFromLast
    nDaysFromLastFormatted: nDaysFromLastFormatted
    maxDate: maxDate
    formatDate: formatDate
    formatUploadDate: formatUploadDate
    fileExtFromUrl: fileExtFromUrl
    fileTypeFromUrl: fileTypeFromUrl
    ngGridFlexibleHeightPlugin: ngGridFlexibleHeightPlugin
    roundMoney: roundMoney
    unformatCurrency: unformatCurrency
    formatCurrency: formatCurrency
    dateFromMilliseconds: dateFromMilliseconds
    isChrome: isChrome
    hasNoValue: hasNoValue
    isTemplateRequest: isTemplateRequest
    prependPartnerProxyAPIPrefix: prependPartnerProxyAPIPrefix
    hasPartnerProxyAPIPrefix: hasPartnerProxyAPIPrefix
    getPartnerProxyAPIPrefix: getPartnerProxyAPIPrefix
    ensureRelative: ensureRelative
  }
